import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { BlogPost } from "../../types/blog";
import { twMerge } from "tailwind-merge";

interface RenderProps {
  filteredBlogs: BlogPost[];
  className?: string;
}

export function Render({ filteredBlogs, className }: RenderProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent text selection
    const ele = scrollContainerRef.current;
    if (!ele) return;

    const startX = event.pageX - ele.offsetLeft;
    const scrollLeft = ele.scrollLeft;
    let isDragging = true;

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;
      const x = e.pageX - ele.offsetLeft;
      const walk = (x - startX) * 2;
      ele.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      isDragging = false;
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent the Link from being activated
    event.stopPropagation(); // Stop the event from bubbling up
  };

  return (
    <div className={twMerge("grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3", className)}>
      {filteredBlogs.map((post) => (
        <Link
          key={post.slug}
          to={`/blog/${post.slug}`}
          className="group flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm transition duration-300 hover:shadow-md dark:border-neutral-800 dark:bg-neutral-900"
        >
          <div className="flex flex-col flex-1 p-6 min-h-34">
            <h2 className="mb-2 text-xl font-semibold text-gray-900 group-hover:text-blue-700 dark:text-gray-100 dark:group-hover:text-blue-300">
              {post.metadata.title}
            </h2>
            <p className="text-sm text-gray-700 dark:text-gray-200">{post.metadata.summary}</p>
          </div>
          <div className="bg-neutral-100 px-6 py-4 dark:bg-neutral-800 flex flex-col justify-start flex-1">
            <p className="text-sm text-gray-700 dark:text-gray-200">
              {new Date(post.metadata.publishedAt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </p>
            <div
              ref={scrollContainerRef}
              className="mt-4 flex flex-nowrap gap-2 overflow-x-auto pb-2 hide-scrollbar"
              onMouseDown={handleMouseDown}
              onClick={handleClick}
              tabIndex={0}
            >
              {post.metadata.tags.slice(0, 2).map((tag) => (
                <Pill tag={tag} />
              ))}
              <Pill tag="..." />
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

function Pill({ tag }: { tag: string }) {
  return (
    <span
      key={tag}
      className="whitespace-nowrap rounded-full bg-gray-200 px-3 py-1 text-xs font-medium text-gray-800 dark:bg-neutral-700 dark:text-gray-100"
    >
      {tag}
    </span>
  );
}
