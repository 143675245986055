import "./global.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Home from "./pages/home";
import Blog from "./pages/blog";
import BlogPost from "./pages/blog/blog-post";
import Work from "./pages/work";
import Contact from "./pages/contact";
import Uses from "./pages/uses";
import NotFound from "./components/NotFound";

const queryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={Home} />
          <Route path="/blog" element={Blog} />
          <Route path="/blog/:slug" element={BlogPost} />
          <Route path="/work" element={Work} />
          <Route path="/contact" element={Contact} />
          <Route path="/contact/:slug" element={Contact} />
          <Route path="/uses" element={Uses} />
          <Route path="/404" element={NotFound} />
          <Route path="*" element={Home} />
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
